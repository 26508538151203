import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Education from './education';
import Experience from './experience';
import Skills from './skills';
import svavar1 from '../Svavar1.png';





class Resume extends Component {
  render() {
    return(
      <div>
        <Grid>
          <Cell col={4}>
            <div style={{textAlign: 'center'}}>
              <img
                src={svavar1}
                alt="avatar"
                className="avatar-img"
                 />
            </div>

            <h2 style={{paddingTop: '2em'}}>Svavar Berg Jóhannsson</h2>
            <h4 style={{color: 'grey'}}>Developer</h4>
            <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
            <p>Svavar Berg Jóhannsson, born and raised in Thorlakshofn. Been playing football since I started walking and grew up with a great family. Always been interested in business, but at the age of 20 I started to grow interest in programming and all the posibilities they can bring. I have a dream of creating something that will change peoples lives and hopefully could help normal people in their activity every day.</p>
            <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
            <h5>Address</h5>
            <p>Finnsbúð 17</p>
            <h5>Phone</h5>
            <p>(354) 770-3613</p>
            <h5>Email</h5>
            <p>Svavarberg7@gmail.com</p>
            <h5>Web</h5>
            <p>svavarberg.com</p>
            <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
          </Cell>
          <Cell className="resume-right-col" col={8}>
            <h2>Education</h2>
            <Education
              startYear={2015}
              endYear={2019}
              schoolName="University of Reykjavik"
              schoolMajor="Computer science with Business Administration as minor"
              schoolDescription="Private unversity located in Nauthólmsvík in Reykjavik. Great engineering and computer science school."
               />
            <Education
                startYear={2011}
                endYear={2014}
                schoolName="FSu"
                schoolMajor="Business and Economics"
                schoolDescription="Small local school located in Selfoss."
                />
            <hr style={{borderTop: '3px solid #e22947'}} />
            <h2>Experience</h2>
            <Experience
              startYear={20018}
              endYear={2019}
              employer="Skeljungur"
              jobTitle="IT department"
              jobDuties="IT service, BI, PSS, Database maintenance"
              jobDescription="At Skeljungur I was employed for two summers and part time in December 2018. My role was to IT customer service, but into the job I started to grow more into the team and started helping them out by taking projects and was used as replacement."
              />
              <Experience
                startYear={2016}
                employer="Árborg"
                jobTitle="Instructor"
                jobDescription="I'm a social supporter for a kid born in 2007."
                />
                <Experience
                startYear={2017}
                employer="Ölfus"
                jobTitle="Instructor"
                jobDuties="Mentor in a social center"
                jobDescription="As a side job I'm working as a instructor or a mentor in a social center in Thorlakshofn for kids 12-15 years old."
                />
              <hr style={{borderTop: '3px solid #e22947'}} />
              <h2>Skills</h2>
                <h3>Web Development</h3>
                    <Skills
                        skill="HTML/CSS"
                        progress={80}
                    />
                    <Skills
                        skill="React"
                        progress={70}
                    />
                <h3>Mobile development</h3>
                    <Skills
                        skill="C#"
                        progress={75}
                    />
                    <Skills
                        skill="Flutter"
                        progress={55}
                    />
                    <Skills
                        skill="SQLite"
                        progress={55}
                    />
                    <Skills
                        skill="Xamarin Android"
                        progress={65}
                    />
                <h3>Databases</h3>
                    <Skills
                        skill="SQL"
                        progress={75}
                    />
                <h3>Other programming languages</h3>
                    <Skills
                        skill="Java"
                        progress={60}
                    />
                    <Skills
                        skill="Python"
                        progress={55}
                    />
                    <Skills
                        skill="C++"
                        progress={75}
                    />
          </Cell>
        </Grid>
      </div>
    )
  }
}
export default Resume