import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';

class Experience extends Component {
  render() {
    return(
      <Grid>
        <Cell col={4}>
          <p>{this.props.startYear} - {this.props.endYear}</p>
          <h8 style={{marginTop: '0px'}}>{this.props.numberOfMonths}</h8>
        </Cell>
        <Cell col={8}>
          <h4 style={{marginTop:'0px'}}>{this.props.employer}</h4>
          <h5 style={{marginTop:'0px'}}>{this.props.jobTitle}</h5>
          <h6 style={{marginTop:'0px'}}>{this.props.jobDuties}</h6>
          <p>{this.props.jobDescription}</p>
        </Cell>
      </Grid>
    )
  }
}

export default Experience;