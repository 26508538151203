import React, { Component } from 'react';
import {Grid, Cell, Card} from 'react-mdl';
import svavar1 from '../Svavar1.png';
import svavar2 from '../Svavar2.jpg';
import Education from './education';
import Experience from './experience';
import Skills from './skills';


class LandingPage extends Component {
    render() {
        return (
            
            <div>
                <div className="firstSights">
                    <Grid>
                        <Cell col={12}>
                            <h2>
                                Svavar Berg Jóhannsson, born 1995 <br></br>
                            </h2>
                            <h4 className="social-links"> 
                                Ég er í starfsleit
                                <br></br>
                                Endilega hafðu samband
                                <br></br>
                                < a href={"mailto: svavarberg7@gmail.com"}>svavarberg7@gmail.com</a>
                                <br></br>
                                <a href="https://www.linkedin.com/in/svavar-berg-johannsson-93578311a/" rel="noopener noreferrer" target="_blank">My linked in: <i className="fa fa-linkedin-square" aria-hidden="true" /></a>
                            </h4>
                        </Cell>
                        <Cell col={3}>

                        </Cell>
                        {/*
                        <Cell col={4}>
                            <div>
                                <img
                                src={svavar2}
                                alt="avatar"
                                className="home-img"
                                />
                            </div>
                        </Cell>
                            
                        */}
                    </Grid>
                    <hr style={{borderTop: '3px solid #bdc3c7'}} />
                </div>
                <div>
                <Grid>
              <Cell col={4}>
                <div style={{textAlign: 'center'}}>
                  <img
                    src={svavar1}
                    alt="avatar"
                    className="avatar-img"
                     />
                </div>
    
                <h2 style={{paddingTop: '2em'}}>Svavar Berg Jóhannsson</h2>
                <h4 style={{color: 'grey'}}>Forritari</h4>
                <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
                <p>Svavar Berg Jóhannsson, fæddur og uppalinn í Þorlákshöfn, lærði tölvunarfræði í Háskólanum í Reykjavík og tók 1. ár af viðskiptafræði með. Ég er alltaf að leitast eftir tækifærum til að aðstoða og bæta mig bæði í leik og starfi.</p>
                <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
                <h4 style={{color: 'grey'}}>Við hvað vill ég starfa?</h4>
                <p>Ég er mjög opinn fyrir störfum, ég er að leita mér að starfi sem ég get fengið að móta mig í sem persóna og sem starfsmaður.</p>
                <p>Sölumaður, áhættugreining, bókhald, forritun, viðskiptagreind, gagnagrunnaforritun - opinn fyrir flestu</p>

                <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
                <h5>Heimilisfang</h5>
                <p>Finnsbúð 17</p>
                <h5>Sími</h5>
                <p>(+354) 770-3613</p>
                <h5>Tölvupóstur</h5>
                <p>Svavarberg7@gmail.com</p>
                <h5>Heimasíða</h5>
                <p>www.svavarberg.com</p>
                <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
                <h4 style={{color: 'grey'}}>Meira um mig</h4>
                <p>Ég bý í Þorlákshöfn hjá foreldrum mínum, ég á fasteign á Selfossi (105fm2 parhús sem var byggt árið 2019). Ég hef æft knattspyrnu alla mína tíð ásamt því að hafa æft körfubolta stóran hluta ævinnar.</p>

                <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>


              </Cell>
              <Cell className="resume-right-col" col={8}>
                <h2>Námsferill</h2>
                <Education
                  startYear={2015}
                  endYear={2019}
                  schoolName="Háskólinn í Reykjavík"
                  schoolMajor="Tölvunarfræði með viðskiptafræði sem aukagrein."
                  schoolDescription="Forritun, NAV forritun, Reikningshald, Gerð og greining ársreikninga, Gagnavinnsla, Viðskiptagreind og fl."
                   />
                   <Education
                startYear={2017}
                endYear={2017}
                schoolName="Virginia Tech"
                schoolMajor="Opin námsleið"
                schoolDescription="Ég fór á fótboltastyrk í Virginia Tech háskólann í Bandaríkjunum, var þar í 4 mánuði en fór heim vegna loforða sem voru brotin."
                   />
                <Education
                    startYear={2011}
                    endYear={2014}
                    schoolName="Fjölbrautaskóli Suðurlands"
                    schoolMajor="Viðskipta- og hagfræðibraut"
                    schoolDescription="Gjaldkeri Nemendafélags FSu árið 2013-14 við góðan orðstír."
                    />
                <hr style={{borderTop: '3px solid #e22947'}} />
                <h2>Starfsreynsla</h2>
                <Experience
                startYear={2019}
                endYear={2020}
                numberOfMonths="4 mánuðir"
                employer="Sjálfstætt starfandi"
                jobTitle="Iðnaðarmaður"
                jobDuties="Kaupa hús - klára hús"
                jobDescription="Ég keypti mér 105fm2 parhús strax eftir nám á byggingastigi 4. og kláraði það. Það gerði ég með mikilli vinnu ásamt aðstoð frá föður mínum sem er iðnaðarmaður."
                />
                <Experience
                startYear={2019}
                endYear={2019}
                numberOfMonths="5 mánuðir"
                employer="Vinnueftirlitið"
                jobTitle="Forritari - Lokaverkefni"
                jobDuties="Þróa smáforrit fyrir starfsmenn VER"
                jobDescription="Lokaverkefnið í HR. Gerðum smáforrit í Xamarin Android (C#) fyrir starfsmenn Vinnueftirlitsins."
                />    
                    <Experience
                    startYear={2018}
                    endYear={2019}
                    employer="Skeljungur"
                    jobTitle="Upplýsingatækni"
                    jobDuties="Notendaþjónusta"
                    jobDescription="Hjá Skeljung starfaði ég tvö sumur ásamt smá í hlutastarfi. Þar var ég ráðinn inn í notendaþjónustu en var aðallegag í afleysingum í kerfisrekstri, viðskiptagreind og kerfi bensínstöðva."
                    />
                    <Experience
                        startYear={2016}
                        employer="Árborg"
                        jobTitle="Leiðbeinandi"
                        jobDescription="Ég hef verið með strák í liðveislu síðan febrúar 2016. Það er strákur fæddur 2008 sem notast við hjólastól og hittumst við alla sunnudaga og gerum skemmtilega hluti."
                        />
                        <Experience
                        startYear={2017}
                        employer="Ölfus"
                        jobTitle="Tómstundaleiðbeinandi"
                        jobDescription="Ég starfa í félagsmiðstöð í aukavinnu, þar er ég næst hæst ráðandi. Þar erum við markvist að kenna og fræða unglina."
                        />
                  <hr style={{borderTop: '3px solid #e22947'}} />
                  <h2>Kunnátta</h2>
                    <h3>Vefforritun</h3>
                        <Skills
                            skill="HTML/CSS"
                            progress={80}
                        />
                        <Skills
                            skill="React"
                            progress={70}
                        />
                        <Skills
                            skill="JS"
                            progress={70}
                        />
                    <h3>Smáforrit</h3>
                        <Skills
                            skill="C#"
                            progress={75}
                        />
                        <Skills
                            skill="Flutter"
                            progress={55}
                        />
                        <Skills
                            skill="SQLite"
                            progress={55}
                        />
                        <Skills
                            skill="Xamarin Android"
                            progress={65}
                        />
                    <h3>Gagnagrunnar</h3>
                        <Skills
                            skill="SQL"
                            progress={75}
                        />
                        <Skills
                            skill="SQLite"
                            progress={75}
                        />
                    <h3>Önnur tungumál</h3>
                        <Skills
                            skill="Java"
                            progress={60}
                        />
                        <Skills
                            skill="Python"
                            progress={55}
                        />
                        <Skills
                            skill="C++"
                            progress={75}
                        />
                        <Skills
                            skill="Flutter"
                            progress={80}
                        />
              </Cell>
            </Grid>
            </div>

            <hr style={{borderTop: '3px solid #bdc3c7'}} />
            
            <div className="contact">
                <h3>
                    Hafðu samband
                </h3> 
            </div>
            <div className="contactDetails">
                    <i className="fa fa-phone-square" aria-hidden="true"/>
                    <a href="tel:+3547703613">(+354) 770-3613</a> 
                    <i className="fa fa-envelope" aria-hidden="true"/>
                    <a href={"mailto: svavarberg7@gmail.com"}>svavarberg7@gmail.com</a>
            </div>
          </div>
        )
    }
}
export default LandingPage