import React, { Component } from 'react';
import { Tabs, Tab, Grid, Cell, Card, CardTitle, CardText, CardActions, Button, CardMenu, IconButton } from 'react-mdl';


class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 0 };
  }

  toggleCategories() {

    if(this.state.activeTab === 0){
      return(
        <div className="projects-grid">
          {/* Project 1 */}
          <Card shadow={5} style={{minWidth: '450', margin: 'auto'}}>
            <CardTitle style={{color: '#fff', height: '176px', background: 'url(https://xtnotes-1255646395.coshk.myqcloud.com/images/react-1.svg) center / cover'}} >React Project #1</CardTitle>
            <CardText>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
            </CardText>
            <CardActions border>
              <Button colored>GitHub</Button>
              <Button colored>CodePen</Button>
              <Button colored>Live Demo</Button>
            </CardActions>
            <CardMenu style={{color: '#fff'}}>
              <IconButton name="share" />
            </CardMenu>
          </Card>
          {/* Project 2 */}
          <Card shadow={5} style={{minWidth: '450', margin: 'auto'}}>
            <CardTitle style={{color: '#fff', height: '176px', background: 'url(https://xtnotes-1255646395.coshk.myqcloud.com/images/react-1.svg) center / cover'}} >React Project #2</CardTitle>
            <CardText>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
            </CardText>
            <CardActions border>
              <Button colored>GitHub</Button>
              <Button colored>CodePen</Button>
              <Button colored>Live Demo</Button>
            </CardActions>
            <CardMenu style={{color: '#fff'}}>
              <IconButton name="share" />
            </CardMenu>
          </Card>
          {/* Project 3 */}
          <Card shadow={5} style={{minWidth: '450', margin: 'auto'}}>
            <CardTitle style={{color: '#fff', height: '176px', background: 'url(https://xtnotes-1255646395.coshk.myqcloud.com/images/react-1.svg) center / cover'}} >React Project #3</CardTitle>
            <CardText>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
            </CardText>
            <CardActions border>
              <Button colored>GitHub</Button>
              <Button colored>CodePen</Button>
              <Button colored>Live Demo</Button>
            </CardActions>
            <CardMenu style={{color: '#fff'}}>
              <IconButton name="share" />
            </CardMenu>
          </Card>
        </div>
      )
    } else if(this.state.activeTab === 1) {
      return (
        <div><h1>This is Angular</h1></div>
      )
    } else if(this.state.activeTab === 2) {
      return (
        <div><h1>This is VueJS</h1></div>
      )
    } else if(this.state.activeTab === 3) {
      return (
        <div>
            <h1>Javascript Section</h1>
            <h3>Calculator</h3>
            <body>
                <form name="calculator">
                    <table>
                        <tr>
                            <td colspan="4">
                                <input type="text" name="display" disabled/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="button" name="one" value="1" onClick="calculator.display.value += '1'"/>
                            </td>
                            <td>
                            <input type="button" name="two" value="2" onClick="calculator.display.value += '2'"/>
                            </td>
                            <td>
                            <input type="button" name="three" value="3" onClick="calculator.display.value += '3'"/>
                            </td>
                            <td>
                            <input type="button" class="operator" name="plus" value="+" onClick="calculator.display.value += '+'"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="button" name="four" value="4" onClick="calculator.display.value += '4'"/>
                            </td>
                            <td>
                            <input type="button" name="five" value="5" onClick="calculator.display.value += '5'"/>
                            </td>
                            <td>
                            <input type="button" name="six" value="6" onClick="calculator.display.value += '6'"/>
                            </td>
                            <td>
                            <input type="button" class="operator" name="minus" value="-" onClick="calculator.display.value += '-'"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="button" name="seven" value="7" onClick="calculator.display.value += '7'"/>
                            </td>
                            <td>
                            <input type="button" name="eight" value="8" onClick="calculator.display.value += '8'"/>
                            </td>
                            <td>
                            <input type="button" name="nine" value="9" onClick="calculator.display.value += '9'"/>
                            </td>
                            <td>
                            <input type="button" class="operator" name="multiply" value="x" onClick="calculator.display.value += 'x'"/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input type="button" id="clear" name="clear" value="c" onClick="calculator.display.value += ''"/>
                            </td>
                            <td>
                            <input type="button" name="zero" value="0" onClick="calculator.display.value += '0'"/>
                            </td>
                            <td>
                            <input type="button" name="doit" value="=" onClick="calculator.display.value = eval(calculator.display.value)"/>
                            </td>
                            <td>
                            <input type="button" class="operator" name="div" value="/" onClick="calculator.display.value += '+'"/>
                            </td>
                        </tr>
                    </table>
                </form>
            </body>
        </div>
      )
    }
  }
  render() {
    return(
      <div>
        <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId })} ripple>
          <Tab>React</Tab>
          <Tab>Angular</Tab>
          <Tab>VueJS</Tab>
          <Tab>Java</Tab>
        </Tabs>
          <Grid>
            <Cell col={12}>
              <div className="content">{this.toggleCategories()}</div>
            </Cell>
          </Grid>
      </div>
    )
  }
}
export default Projects;