import React, { Component } from 'react';
import { Grid, Cell, List, ListItem, ListItemContent } from 'react-mdl';
import svavar from '../Svavar1.png';


class Contact extends Component {
  render() {
    return(
      <div className="contact-body">
        <Grid className="contact-grid">
          <Cell col={7}>
            <h2>Svavar Berg Jóhannsson</h2>
            <img
              src={svavar}
              alt="avatar"
              style={{height: '250px'}}
               />
             <p style={{ width: '75%', margin: 'auto', paddingTop: '1em'}}>
                Svavar Berg Jóhannsson, fæddur 16. apríl 1995. Uppalinn í Þorlákshöfn, fór i Grunnskólann í Þorlákshöfn og þaðan í Fjölbrautaskóla Suðurlands (FSu) og útskrifaðist þaðan af viðskipta- og hagfræðibraut. Skólaárið 2013-14 þá var ég gjaldkeri Nemendafélags Fjölbrautaskóla Suðurlands (NFSu) með góðum árangri. Eftir framhaldsskóla fór ég í Háskólann í Reykjavík að leggja stund á tölvunarfræði og tók ég viðskiptafræði sem aukagrein. Því námi lauk ég árið 2019 með smá viðveru í Virginia Tech háskólanum í Bandaríkjunum.
             </p>
          </Cell>
          <Cell col={5}>
            <h2>Contact Me</h2>
            <hr/>
            <div className="contact-list">
              <List>
                <ListItem>
                  <ListItemContent style={{fontSize: '30px', fontFamily: 'Anton'}}>
                    <i className="fa fa-phone-square" aria-hidden="true"/>
                    <a href="tel:+3547703613">(+354) 770-3613</a>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent style={{fontSize: '30px', fontFamily: 'Anton'}}>
                    <i className="fa fa-envelope" aria-hidden="true"/>
                    < a href={"mailto: svavarberg7@gmail.com"}>svavarberg7@gmail.com</a>
                  </ListItemContent>
                </ListItem>
                <ListItem>
                  <ListItemContent style={{fontSize: '30px', fontFamily: 'Anton'}}>
                    <i className="fa fa-skype" aria-hidden="true"/>
                    <a href="skype:svavarberg7?call">Skype -> Svavarberg7</a>
                  </ListItemContent>
                </ListItem>
              </List>
            </div>
          </Cell>
        </Grid>
      </div>
    )
  }
}
export default Contact