import React, { Component } from 'react';
import {Grid, Cell} from 'react-mdl';
import { Tabs, Tab, Card, CardTitle, CardText, CardActions, Button, CardMenu, IconButton } from 'react-mdl';


class Thoughts extends Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: 0 };
      }
    
      toggleCategories() {
    
        if(this.state.activeTab === 0){
          return(
            <div>
                <h1 style={{textAlign: 'center'}}>Welcome to the blog</h1>
                <Card shadow={5} style={{minWidth: '450', width: '90%', margin: 'auto', marginBottom: '5px'}}>
                    <div>

                        
                    </div>
                </Card>
            </div>
            
          )
        } else if(this.state.activeTab === 1) {
          return (
            <div>
                <h1>Welcome to the photo section!</h1>
                <Card shadow={5} style={{minWidth: '450', width: '90%', margin: 'auto'}}>
                    
                </Card>
            </div>
          )
        } 
      }
      render() {
        return(
          <div>
            <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId })} ripple>
              <Tab>Blog</Tab>
              <Tab>Photos</Tab>
            </Tabs>
              <Grid>
                <Cell col={12}>
                  <div className="content">{this.toggleCategories()}</div>
                </Cell>
              </Grid>
          </div>
        )
      }
    }
export default Thoughts;